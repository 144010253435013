<div>
  <mat-card class="main-card">
    <mat-card-header>

    </mat-card-header>
    <mat-card-content>
      <img mat-card-image src="../../assets/angular_logo.png" height="380" width="380" alt="Logo Angular"/>
      <div class="div-button">
        <button mat-raised-button color="warn" routerLink="/auteurs">GO !</button>
      </div>
    </mat-card-content>
  </mat-card>
</div>